.alumnus{
  @apply grid gap-4 pb-14
  md:gap-12
}

.alumnus__header{
  @apply grid grid-flow-row gap-y-4 px-4 pt-14 pb-6 bg-gray-200
  md:px-20
  md:pb-12
  md:pt-16
}

.alumnus__body{
  @apply px-2
  md:px-20
}

.alumnus__header-location{
  @apply w-min justify-self-end flex items-center bg-white rounded-3xl py-1 px-2 border border-gray-100 text-xs text-gray-600
}
.alumnus__header-location svg{
  @apply mr-1 text-sm fill-primary
}


.alumnus__header .image{ 
  @apply flex-none w-16
  md:w-36
}

.alumnus__header .image img{ 
  @apply rounded-full
}

.alumnus__header .name-div{ 
  @apply flex-auto
}

.alumnus__header .name-div .name{
  @apply text-2xl font-semibold text-gray-900
  md:text-4xl
}

.alumnus__header .name-div .username{
  @apply text-xs font-normal text-gray-600 
  md:text-base
}

.alumnus__header .name-div .job{
  @apply text-sm font-normal text-gray-600

}



.alumnus__header .username{
  @apply text-sm text-gray-600 
}
.alumnus__header .buttons{
  @apply flex w-full space-x-2 mt-2
}

.alumnus__header .buttons button{
  @apply w-6/12
  md:w-3/12
}


/* study section */

.alumnus__study{
  @apply flex flex-col rounded-md space-y-3
  md:w-full
  md:flex-row
  md:space-y-0
  md:space-x-4
}
 
.alumnus__study .item{
  @apply p-2 flex flex-col bg-white rounded-md
  md:w-4/12
  md:h-full
}

.alumnus__study .item .title{
  @apply text-primary font-semibold text-xs
}

.alumnus__study .item .value{
  @apply text-sm text-gray-600
}

/* collegues section */
.alumnus__collegues{
  @apply bg-white px-3 py-3 rounded-md
  md:w-4/12
}

.alumnus__collegues .title{
@apply text-base text-primary font-semibold mb-3
}

.alumnus__collegues-inner{
@apply grid content-start overflow-scroll gap-y-2 h-96
}

.alumnus__collegues-inner .item{
@apply grid grid-cols-max-1fr-min grid-rows-2 gap-x-2 p-2 bg-gray-50 rounded-md border border-gray-100
}

.alumnus__collegues-inner .item .image{
  @apply w-12 h-12 row-span-2 rounded-full 
}

.alumnus__collegues-inner .item .name{
  @apply col-start-2 justify-self-start text-left row-start-1  self-end text-sm text-gray-600
}

.alumnus__collegues-inner .item .username{
  @apply col-start-2 justify-self-start text-left row-start-2 self-start text-xs text-gray-500
}

.alumnus__collegues-inner .item .location{
  @apply w-min self-center col-start-3 row-span-2 justify-self-end flex items-center bg-white rounded-3xl py-1 px-2 border border-gray-100 text-xs text-gray-600
}

.alumnus__collegues-inner .item .location svg{
  @apply row-span-2 mr-1 text-sm fill-primary 
}






