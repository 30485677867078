.profile-edit{
  @apply pb-8
}
.profile-edit .form{
  @apply flex flex-col space-y-4
}

.profile-edit__image, .profile-edit__details{
  @apply md:mx-auto
  md:w-96
}

.profile-edit__image{
  @apply  bg-white mb-6 flex justify-center pb-7 pt-20
}



.profile-edit__image-image{
  @apply h-36 w-36 rounded-full
}

.profile-edit__details{
  @apply px-4
  md:px-0
}


.profile-edit__detail{
  @apply flex flex-col space-y-2 px-4 py-4 bg-white rounded
}

.profile-edit__detail .title{
  @apply text-sm font-semibold text-primary mb-1
  md:text-base
}
