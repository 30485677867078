.profile__head{
  @apply grid
  justify-center
  content-center
  py-7 px-4
  bg-gray-200
  text-center
}

.profile__image{
  @apply w-40 h-40 rounded-full justify-self-center mb-1
}

.profile__username{
  @apply font-bold text-primary text-sm
}

.profile__name{
  @apply text-lg font-medium text-gray-700
}

.profile__job{
  @apply text-gray-500
}

.profile__edit-button{
  @apply mt-4
}

.profile__details{
  @apply grid grid-cols-1 mt-8 gap-y-4
  px-4
  md:px-4
  lg:px-8
  md:gap-x-4
  md:grid-cols-2
  lg:grid-cols-4

}

.profile-section{
  @apply bg-white border border-gray-100 px-4 py-3
  rounded-md divide-y divide-gray-200

}

.profile-section .title{
  @apply text-primary-dark font-semibold text-sm mb-4
  md:text-base
}
.profile-section .item{
  @apply flex flex-col pt-2 pb-2 
}

.profile-section .item .label{
  @apply text-primary font-semibold text-xs
}

.profile-section .item .data{
  @apply text-sm text-gray-600
}





