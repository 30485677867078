.progress{
  @apply flex flex-col w-80

  lg:w-96
}

.progress__title{
  @apply flex flex-row justify-between pb-1
}

.progress__title-name, .progress__title-percent {
  @apply text-gray-700 font-semibold text-sm
}

.progress__inner{
  @apply h-4 rounded-lg bg-gray-300
}

.progress__inner-bar{
  @apply h-full  rounded-lg bg-primary
}
