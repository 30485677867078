

/* SUMMARY SECTION  */
.directory__summary .metric{
  @apply flex
}

.directory__summary{
  @apply flex flex-col
}

.directory__summary-title{
  @apply text-base font-semibold text-primary mb-2
}

.directory__summary-inner{
  @apply grid grid-cols-2 gap-y-3 gap-x-3
  md:grid-cols-3
}

.directory__summary .metric{
  @apply flex flex-col rounded border shadow-sm border-gray-100 p-2 space-y-1 bg-white
}

.directory__summary .metric .title{
  @apply flex flex-row text-sm items-center text-gray-600
}

.directory__summary .metric .title svg{
  @apply mr-2 fill-primary
}

.directory__summary .metric .number{
  @apply font-black text-lg text-gray-500
}

/* DATA SECTION */

.directory__data{
  @apply flex max-w-md py-1 flex-col rounded-md shadow-sm border border-gray-100 bg-white
}

.directory__data-title{
  @apply text-base font-semibold text-primary px-3
}

.directory__data-inner{
  @apply flex flex-col space-y-2 px-3 py-2 
}

.directory__data .metric{
  @apply flex bg-gray-100 justify-between px-2 py-1 rounded-sm text-gray-600 text-sm
}