/* FORM STYLES */

.form{
  @apply grid
}

.form-group{
  @apply mt-1 relative rounded-md shadow-sm;
}

.form-group__label{
  @apply block text-xs font-medium text-gray-400 mb-2
  /* tablet screens and above */
  md:font-semibold md:text-sm; 
}

/* form labels that belong inside an input tag */
.inner-label > .form-group__label{
  @apply absolute origin-top-left mt-2 pl-3;
}

.form-group__error-message{
  @apply text-red-700 text-xs mt-1
}

.form-group__input{
  @apply focus:ring-indigo-500 shadow-none text-primary-dark focus:border-primary block w-full pl-2 pr-12 pt-2 pb-2 text-base border border-gray-300 rounded
  /* tablet screens and above */
  md:text-sm;
}

.form-group__input::placeholder{
  @apply align-middle
}

.form-group__input-search{
  @apply focus:ring-indigo-500 shadow-none text-primary-dark focus:border-primary block w-full pl-2 pr-12 pt-2 pb-2 text-base border border-gray-100 rounded-lg
  /* tablet screens and above */
  md:pt-4
  md:pb-4
  md:pl-4
  md:text-lg
}

.form-group__input-search--submit, .form-group__input-search--filter{
  @apply absolute h-full right-0 top-0 px-1
}

.form-group__input-search--filter{
  @apply flex px-2 right-14 items-center border-l border-gray-100
  md:right-20
  md:px-4
}

.form-group__input-search--submit{
  @apply px-5 border-l border-gray-200 bg-primary-light rounded-r-lg
  md:px-8
}

.form-group__input-search--submit svg{
  @apply text-base
}

.form-group__input-search--submit svg{
  @apply text-base
}


.inner-label > .form-group__input{
  @apply pt-7  
}

.select-group{
  @apply absolute inset-y-0 right-0 flex items-center;
}

.form-group__select{
  @apply border w-full border-gray-300 focus:ring-indigo-500 focus:border-indigo-500 h-full pl-2 pr-12 pt-2 pb-2 text-primary-dark text-base rounded md:text-base
}

.btn-full{
  @apply text-sm bg-primary items-center transition duration-300 hover:bg-primary-light text-center text-white font-medium py-2 px-4  rounded-md focus:outline-none;
}

.btn-empty{
  @apply text-sm text-primary border border-primary items-center transition duration-300 hover:bg-primary-light text-center font-medium py-2 px-4 rounded-md focus:outline-none;
}

/* FORM ERROR MESSAGE */

.form-message{
  @apply border border-red-500 rounded-lg bg-white p-4
}

.form-message.hide{
  @apply hidden
}

.form-message__top{
  @apply flex items-center justify-between 
}

.form-message__notification{
@apply flex items-center 
}

.form-message__title{
  @apply text-sm text-red-700 font-semibold
  }

.form-message__icon{
  @apply mr-4
}

.form-message__close{
  @apply justify-self-end cursor-pointer
}

.form-message__icon svg{
  @apply text-lg fill-red
}

.form-message__message{
  @apply text-xs pl-8 pt-2  text-gray-600
}
