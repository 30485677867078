.appbar-b{
 @apply flex
 fixed
 bg-primary-dark
 w-full
items-stretch
 bottom-0
 py-3
 px-2
}

.appbar-b__link{
  @apply flex-grow flex flex-col items-center
}

.appbar-b__link-name{
  @apply text-xs  text-gray-50
}
.appbar-b__link svg{
  @apply text-base fill-gray-50
}

.appbar-b__link.selected svg{
  @apply text-base fill-primary
}


.appbar-b__link.selected .appbar-b__link-name{
  @apply text-primary
}
