.login-message{
  @apply grid content-center gap-1 text-center text-white
   px-4
    bg-primary
    md:h-screen
    md:text-left
    md:px-14
    md:gap-2
  
}

.login-message__head{
  @apply font-normal text-sm
  md:text-xl
}

.login-message__body{
  @apply font-bold text-2xl
  md:text-3xl
}

.login-message__sub{
  @apply font-normal text-sm
  md:text-base
}

.login-form{
  @apply  grid gap-4 content-center px-3 py-7
  md:py-0
  md:h-screen
  md:justify-center
}

.login-form .form-group, .login-form .form-message{
  @apply 
  md:w-96
}
