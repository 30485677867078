.menu__header{
  @apply grid h-56 content-center px-4
  bg-gradient-to-tr from-primary to-primary-light
  rounded-b-3xl

  md:px-8
}

.menu__header .name{
  @apply text-white text-2xl font-semibold
  md:text-3xl
}

.menu__header .id{
  @apply text-white text-xs font-semibold
  md:text-sm
}

.menu__header .message{
  @apply text-white text-sm mt-2
  md:text-base
  md:mt-3
}

.menu__body{
  @apply grid gap-y-3 gap-x-3 py-5 px-4

  sm:grid-cols-2
  md:px-8
  md:py-11
  md:grid-cols-3
  md:gap-x-5
  md:gap-y-5
  md:content-center
}


.menu__body .item{
  @apply grid text-lg gap-x-1 gap-y-1 p-3 border border-gray-200 shadow-sm rounded transition-all cursor-pointer
  hover:shadow-md
}

.menu__body .item{
  grid-template-columns: 3rem 1fr;
}

.menu__body .item svg{
  @apply row-span-2 self-center justify-center text-4xl text-primary
}

.menu__body .item .title{
  @apply  flex justify-between text-base text-primary font-semibold
}

.menu__body .item .comment{
  @apply text-xs text-gray-700
}