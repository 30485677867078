.nav{
  @apply
  flex fixed w-full flex-row items-center bg-white md:space-x-5 z-50
}

.nav__direction{
  @apply p-3 bg-gray-300 
  md:p-4
}

.nav__direction svg{
  @apply fill-dark
}

.nav__direction-name{
  @apply w-full text-center text-gray-500
  /* md:w-max */
  md:text-left

}

.nav__menu{
  @apply justify-self-end pr-5
}

.nav__menu svg{
  @apply text-xl
}

.nav__menu-content{
  @apply absolute hidden top-9 right-2 rounded w-40 bg-white shadow-sm border border-gray-200
  
}

.nav__menu-content.show{
  @apply block
}

.nav__menu-content a{
  @apply block py-3 px-4 text-gray-600 text-sm
}

.nav__menu-content a:hover{
@apply bg-gray-100
}